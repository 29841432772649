import PropTypes from 'prop-types';
import { Avatar, Box, Chip, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { BoxContainer } from '@layouts';
import { ThreeDotsMenu } from '@molecules';
import styles from './styles';

/**
 * Renders a subtitle with an optional icon and text
 * @param {Object} props - The subtitle props
 * @param {ReactNode} props.icon - The icon to be displayed before the text
 * @param {string} props.text - The text to be displayed
 * @returns {JSX.Element} - The rendered subtitle
 */
const Subtitle = ({ icon, text }) => {
  return (
    <Box sx={styles.subtitleContainer}>
      {icon && <SvgIcon>{icon}</SvgIcon>}
      <Typography variant='body' sx={styles.subtitle}>
        {text}
      </Typography>
    </Box>
  );
};

Subtitle.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
};

/**
 * Card header component
 */
const CardHeader = ({
  title,
  chipLabel,
  leftComponentOptions,
  rightComponentOptions,
  withMenu,
  subtitleOptions,
}) => {
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  /**
   * @returns the left component depending on the type
   */
  const renderLeftComponent = () => {
    switch (leftComponentOptions.type) {
      case 'avatar':
        return (
          <Avatar sx={styles.avatar}>
            <SvgIcon>{leftComponentOptions.icon}</SvgIcon>
          </Avatar>
        );
      case 'icon':
        return (
          <SvgIcon sx={leftComponentOptions.customStyle?.icon}>{leftComponentOptions.icon}</SvgIcon>
        );
      default:
        return null;
    }
  };

  /**
   * @returns the right component depending on the type
   */
  const renderRightComponent = (renderAll) => {
    if (
      renderAll &&
      rightComponentOptions &&
      rightComponentOptions.text &&
      rightComponentOptions.menuOptions
    ) {
      return (
        <Stack direction='row' spacing={1}>
          <Box sx={styles.rightTextContainer}>
            <Typography variant='h1'>{rightComponentOptions.text}</Typography>
            {rightComponentOptions.icon && (
              <Box onClick={rightComponentOptions.onClickIcon} sx={styles.iconContainer}>
                <SvgIcon>{rightComponentOptions.icon}</SvgIcon>
              </Box>
            )}
          </Box>
          <ThreeDotsMenu options={rightComponentOptions.menuOptions} />
        </Stack>
      );
    }

    switch (rightComponentOptions.type) {
      case 'threeDotsMenu':
        return <ThreeDotsMenu options={rightComponentOptions.menuOptions} />;
      case 'text':
        return (
          <Box sx={styles.rightTextContainer}>
            <Typography variant='h1'>{rightComponentOptions.text}</Typography>
            {rightComponentOptions.icon && (
              <Box onClick={rightComponentOptions.onClickIcon} sx={styles.iconContainer}>
                <SvgIcon>{rightComponentOptions.icon}</SvgIcon>
              </Box>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  /**
   * @function
   * @description Renders the subtitle with optional icon and text
   * @returns {React.ReactElement | null} The rendered subtitle or null if no subtitle options are provided
   */
  const renderSubtitle = () => {
    if (!subtitleOptions) return null;

    if (Array.isArray(subtitleOptions)) {
      return (
        <Stack spacing={0.5}>
          {subtitleOptions.map((option) => (
            <Subtitle key={option.text} {...option} />
          ))}
        </Stack>
      );
    }
    return <Subtitle icon={subtitleOptions.icon} text={subtitleOptions.text} />;
  };

  return (
    <BoxContainer>
      <Box sx={styles.container(responsive)}>
        <Box sx={styles.leftContainer}>
          {renderLeftComponent()}
          <Box>
            <Box sx={styles.titleContainer(responsive)}>
              <Typography variant='h2'>{title}</Typography>
              {chipLabel && <Chip label={chipLabel} size='small' />}
            </Box>
            {renderSubtitle()}
          </Box>
        </Box>
        {renderRightComponent(withMenu)}
      </Box>
    </BoxContainer>
  );
};
const subtitlePropTypes = PropTypes.shape({
  icon: PropTypes.elementType,
  text: PropTypes.string,
});

CardHeader.propTypes = {
  title: PropTypes.string,
  chipLabel: PropTypes.string,
  leftComponentOptions: PropTypes.shape({
    type: PropTypes.string,
    icon: PropTypes.elementType,
    customStyle: PropTypes.shape({
      icon: PropTypes.object,
    }),
  }),
  rightComponentOptions: PropTypes.shape({
    type: PropTypes.string,
    menuOptions: PropTypes.object,
    text: PropTypes.text,
    icon: PropTypes.elementType,
    onClickIcon: PropTypes.func,
  }),
  withMenu: PropTypes.bool,
  subtitleOptions: PropTypes.oneOf([subtitlePropTypes, PropTypes.arrayOf(subtitlePropTypes)]),
};
export default CardHeader;
