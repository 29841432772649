import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  ChipTypeMap,
  AutocompleteProps as MUIAutocompleteProps,
  TextFieldProps,
  AutocompleteValue,
} from '@mui/material';
import styles from './styles';
import Input, { InputProps } from '../Input';

interface Option {
  label: string;
  value: string;
}

interface AutocompleteProps<
  OptionItem extends object,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType
> extends Omit<
    MUIAutocompleteProps<OptionItem, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'onChange' | 'renderInput' | 'options' | 'getOptionLabel'
  > {
  label: string;
  margin?: TextFieldProps['margin'];
  error?: boolean;
  helperText?: string;
  inputProps?: Partial<InputProps>;
  variant?: 'outlined' | 'filled';
  items: OptionItem[];
  parseOption?: (item: OptionItem) => Option;
  onChange?: (
    event: Omit<React.SyntheticEvent, 'target'> & {
      target: {
        value: AutocompleteValue<Option, Multiple, DisableClearable, FreeSolo> | string;
      };
    }
  ) => void;
}

/**
 * Autocomplete component with complex functionality.
 * @param {Object} props - Component props.
 * @param {Array} props.items - Options data.
 */
const AutocompleteComplex = <
  OptionItem extends object,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>({
  label,
  margin = 'normal',
  value,
  size = 'medium',
  error,
  onChange,
  onBlur,
  helperText,
  variant = 'outlined',
  inputProps = {},
  sx = {},
  disabled = false,
  items,
  placeholder = 'COMMON:SELECT_PLACEHOLDER',
  freeSolo,
  parseOption,
  multiple,
}: AutocompleteProps<OptionItem, Multiple, DisableClearable, FreeSolo, ChipComponent>) => {
  const { t } = useTranslation();

  const selectOptions = items && (parseOption ? items.map((item) => parseOption(item)) : items);

  /**
   * Handles change event.
   * @param  _event - React SyntheticEvent object
   * @param  value - New value
   */
  const handleChange = (
    _event: Omit<React.SyntheticEvent, 'target'>,
    value: AutocompleteValue<Option, Multiple, DisableClearable, FreeSolo>
  ) => {
    onChange && onChange({ ..._event, target: { value } });
  };

  /**
   * Handles change event.
   * @param _event - React SyntheticEvent object
   * @param value - New value
   */
  const handleInputChange = (_event: React.SyntheticEvent, value: string) => {
    onChange && onChange({ ..._event, target: { value } });
  };

  return (
    <Autocomplete<Option, Multiple, DisableClearable, FreeSolo>
      freeSolo={freeSolo}
      multiple={multiple}
      options={selectOptions as Option[]}
      getOptionLabel={(option) =>
        typeof option === 'object' && 'title' in option
          ? (option.title as string)
          : typeof option === 'object' && 'label' in option
          ? (option.label as string)
          : (option as string)
      }
      size={size}
      onChange={handleChange}
      onInputChange={freeSolo ? handleInputChange : undefined}
      onBlur={onBlur}
      disabled={disabled}
      sx={styles.root}
      renderInput={({ InputProps, disabled, id, size, inputProps: htmlInputProps }) => {
        return (
          <Input
            fullWidth
            id={id}
            disabled={disabled}
            variant={variant}
            label={t(label)}
            placeholder={t(placeholder)}
            margin={margin}
            helperText={helperText}
            error={error}
            sx={sx}
            value={value}
            InputProps={InputProps}
            inputProps={htmlInputProps}
            size={size}
            {...inputProps}
          />
        );
      }}
    />
  );
};

export default AutocompleteComplex;
