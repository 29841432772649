import { FC } from 'react';
import { t } from 'i18next';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, SvgIcon, CardHeader, Stack } from '@mui/material';
import { Button } from '@atoms';
import { useBoolean, useProductsByCompany } from '@hooks';
import { CompanyPostalAddress } from '@modules/clients/data/PlacesRepository/address/services/getPostalAddressByCompanyId';
import { PostalAddressDetail, ProductsAvailableList } from './components';
import styles from '../../styles';

interface CompanyDetailsProps {
  companyId: number;
  isOperationRole: boolean;
  companyPostalAddress: CompanyPostalAddress | undefined;
  isLoading: boolean;
}

/** Render company details address */
const CompanyDetails: FC<CompanyDetailsProps> = ({
  companyId,
  isOperationRole,
  companyPostalAddress,
  isLoading: isLoadingCompanyPostalAddress,
}) => {
  const { data: products } = useProductsByCompany({ allowRequest: isOperationRole, companyId });
  const { isOpen: addressExpanded, toggle: toggleAddress } = useBoolean(true);

  return (
    <Card>
      <CardHeader
        title={t('CLIENTS:DETAIL:POSTAL_ADDRESS:TITLE')}
        titleTypographyProps={{ typography: 'h2', flex: 'none' }}
        sx={styles.headerContent}
        action={
          companyPostalAddress && (
            <Button
              customStyle={styles.toggleAddress}
              startIcon={
                <SvgIcon>{addressExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</SvgIcon>
              }
              color='secondary'
              variant='link'
              onClick={toggleAddress}
            />
          )
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <Stack spacing={6}>
          <PostalAddressDetail
            companyPostalAddress={companyPostalAddress}
            isExpanded={addressExpanded}
            isLoading={isLoadingCompanyPostalAddress}
            isOperationRole={isOperationRole}
          />
          {isOperationRole && <ProductsAvailableList products={products} />}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CompanyDetails;
