import useSWR from 'swr';
import getPostalAddressByCompanyId from '../services/getPostalAddressByCompanyId';

/** Handle state of fetching postal address by company id */
export default function usePostalAddresByCompanyId(companyId: string) {
  const props = useSWR(companyId ? `partner-${companyId}-postal-address` : null, () =>
    getPostalAddressByCompanyId(companyId)
  );

  return props;
}
