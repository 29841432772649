import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { isAxiosError } from 'axios';
import { Client } from 'helpers/hooks/useEmployeeDetails';
import { KeyedMutator } from 'swr';
import { AlertColor, Box, CardHeader, List, Stack, Typography, Skeleton } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { apiClient } from 'config/api';
import { PropertyListItem, Alert as AlertAtom, Input } from '@atoms';
import { PERMISSIONS } from '@globalConstants';
import {
  useItemListToForm,
  useUser,
  useFilterInternalFeatures,
  useCategories,
  useSelectedCompanyCustomAndGenericCategories,
  usePermissions,
} from '@hooks';
import { Chip, SelectFetch as Select, ThreeDotsMenu } from '@molecules';
import { NubiTheme } from '@theme';
import { CATEGORIES_EDITOR_ROLES, EDIT_ERRORS, ROWS, RowItem } from './constants';
import { styles, propertyListItemStyle } from './styles';
import AllowDispersion from '../AllowDispersion';
import PrepaidCard from '../PrepaidCard';
import { Product } from '../useProducts';

interface ProductCardProps {
  product: Product;
  productsMutate: KeyedMutator<Product[]>;
  isAllowedToRetrieveBalance?: boolean;
  onPressRetrieveBalance: (product: Product) => void;
  showSnackbar: (message: string, severity: AlertColor) => void;
  client: Client;
}

interface RequestPhysicalCardModalInfo {
  text?: string;
  values?: object;
  variant: string;
  title: string;
  handleSubmit?: () => void;
}

/**
 * Product details component
 */
const ProductCard = ({
  isAllowedToRetrieveBalance,
  product,
  productsMutate,
  onPressRetrieveBalance,
  showSnackbar,
  client,
}: ProductCardProps) => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { user } = useUser();

  const [isEditing, setEditing] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [editErrorSameProduct, setEditErrorSameProduct] = useState(false);

  const [isMutating, setMutating] = useState(false);

  const [requestPhysicalCardModalInfo, setRequestPhysicalCardModalInfo] =
    useState<RequestPhysicalCardModalInfo | null>(null);
  const [isLoadingRequestPhysicalCard, setIsLoadingRequestPhysicalCard] = useState(false);
  const [isloadingPostalAddress, setIsloadingPostalAddress] = useState(false);
  const hasRequestPhysicalCardPermission = usePermissions([PERMISSIONS.requestPhysicalCardButton]);
  const hasEditUserBenefitPermission = usePermissions([PERMISSIONS.editUserBenefit]);

  /**
   * Returns activated virtual card
   */
  const activatedVirtualCard = product.cards.find(
    (item) => item.kind === 'VIRTUAL' && item.status === 'ACTIVATED'
  );

  /**
   * Check if the account has an business card
   */
  const hasBusinessCard = product.cards.find((item) => item.card_type === 'BUSINESS');

  /**
   * Check if the user has an activated virtual card
   */
  const hasActivatedVirtualCard = activatedVirtualCard && product.cards.length === 1;

  const hasRequestedPhysicalCard =
    activatedVirtualCard && activatedVirtualCard.physical_card_request_date;

  const canViewRequestPhysicalCardButton =
    hasRequestPhysicalCardPermission && hasBusinessCard && hasActivatedVirtualCard;

  const canViewOptionToEditCategories = user?.isInternal
    ? hasEditUserBenefitPermission
    : hasEditUserBenefitPermission && product.product !== 'Almuerzo';

  /**
   * Parses categories options into an object with text and value fields.
   * @param item - The options object.
   * @param item.name - The value to be used as the label and value.
   * @returns - An object containing the text and value fields.
   */
  const parseOptionAllCategories = (item: { name: string }) => ({
    text: `CATEGORIES:${item?.name}`,
    value: item?.name,
  });
  /**
   * Parses categories options custom and generic into an object with text and value fields.
   * @param name - The value to be used as the label and value.
   * @returns - An object containing the text and value fields.
   */
  const parseOptionCustomAndGenericCategories = (name: string) => ({
    text: `CATEGORIES:${name}`,
    value: name,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputComponents: any = {
    Input,
    Select,
  };

  const filteredRows = useFilterInternalFeatures(ROWS);
  const rows: RowItem<Product, string>[] = filteredRows.map((row: RowItem<Product, string>) => {
    if (row.label === 'EMPLOYEES:DETAIL:ENABLE_DISPERSION') {
      return {
        ...row,
        ActionComponent: (
          <AllowDispersion
            allowDispersion={product.allow_dispersion}
            accountId={product.account_id}
            onSuccess={productsMutate}
          />
        ),
      };
    }

    return row;
  });

  /** Render product row item */
  const renderRow = (row: RowItem<Product, string>) => {
    const rowValue = row?.value(product, client);
    const inputName = row.inputName;
    const isAllowedToEdit = user?.roles.some((role: string) =>
      CATEGORIES_EDITOR_ROLES.includes(role)
    );

    if (!rowValue && !row.ActionComponent) return null;

    if (isEditing && isAllowedToEdit && row.editable) {
      const isSelect = row.inputType === 'select';
      const Component = inputComponents[isSelect ? 'Select' : 'Input'];

      const getOptions = user?.isInternal
        ? useCategories
        : useSelectedCompanyCustomAndGenericCategories;
      const parseOptions = user?.isInternal
        ? parseOptionAllCategories
        : parseOptionCustomAndGenericCategories;

      return (
        inputName && (
          <Component
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, inputName)}
            label={row.label}
            name={inputName as string}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={values[inputName] as any}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error={errors[inputName] as any}
            helperText={typeof errors[inputName] === 'string' ? errors[inputName] : null}
            getOptions={getOptions}
            parseOption={parseOptions}
            multiple={row.props?.multiple}
            setFieldValue={setFieldValue}
            {...(isSelect && {
              customOnConfirmClick: () => handleSubmit(),
              isLoadingConfirmButton: isSubmitting,
              customOnCancelClick: () => setEditing(false),
              showOpenModal: true,
            })}
          />
        )
      );
    }

    const rowValueElement =
      !rowValue && row.ActionComponent ? (
        row.ActionComponent
      ) : (
        <Typography
          variant='body1'
          sx={[styles.value, (row.customStyle?.value as SystemStyleObject<NubiTheme>) ?? false]}
        >
          {rowValue}
        </Typography>
      );

    const valueItem =
      isAllowedToRetrieveBalance && rowValue && row.ActionComponent ? (
        <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1}>
          {rowValueElement}
          {row.ActionComponent}
        </Stack>
      ) : (
        rowValueElement
      );

    return (
      <Box key={`Box-${row.label}`}>
        <PropertyListItem label={row.label} value={valueItem} customStyle={propertyListItemStyle} />
      </Box>
    );
  };

  /**
   * Sends request to backend to edit product categories
   */
  const onSubmitEdit = async (values: Partial<Product>) => {
    try {
      setSubmitting(true);
      await apiClient.patch(`/client/change-benefit/${product.account_number}`, {
        benefitsAvailable: values.categories,
      });
      setEditing(false);
      showSnackbar(t('EMPLOYEES:DETAIL:EDIT_CATEGORIES_SUCCESS'), 'success');
      handleMutate();
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response?.data.errors[0]?.code === EDIT_ERRORS.SAME_PRODUCT_CATEGORIES
      ) {
        setEditErrorSameProduct(true);
      } else {
        showSnackbar(t('COMMON:ERRORS:DEFAULT_ERROR'), 'error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  /**
   *
   */
  const handleMutate = async () => {
    try {
      setMutating(true);
      await productsMutate();
    } catch (error) {
      showSnackbar('Ocurrió un error actualizando los productos', 'error');
    } finally {
      setMutating(false);
    }
  };

  const { formProps } = useItemListToForm(ROWS, product, onSubmitEdit);

  const { values, errors, handleChange, setFieldValue, handleSubmit } = formProps;

  /**
   * Check if the client has a postal address and open the modal to request the physical card
   */
  const handleRequestPhysicalCard = async () => {
    try {
      setIsloadingPostalAddress(true);
      const { data: companyPostalAddress } = await apiClient.get(
        `/partner/address?customerId=${client.customerId}`
      );
      if (companyPostalAddress) {
        const floor = companyPostalAddress.floor
          ? String(
              t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:CONFIRM_MODAL_TEXT_FLOOR', {
                floor: companyPostalAddress.floor,
              })
            )
          : '';
        const apartment = companyPostalAddress.apartment
          ? String(
              t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:CONFIRM_MODAL_TEXT_APARTMENT', {
                apartment: companyPostalAddress.apartment,
              })
            )
          : '';
        setRequestPhysicalCardModalInfo({
          title: t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:CONFIRM_MODAL_TITLE'),
          text: 'EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:CONFIRM_MODAL_TEXT',
          values: {
            legalName: client.customerLegalName,
            postalAddress: `${companyPostalAddress.street} ${companyPostalAddress.number}`,
            floor,
            apartment,
            cityName: companyPostalAddress.cityName,
            stateName: companyPostalAddress.stateName,
          },
          variant: 'warning',
          handleSubmit: handleConfirmRequestPhysicalCard,
        });
      } else {
        setRequestPhysicalCardModalInfo({
          title: t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:MISSING_POSTAL_ADDRESS_TITLE'),
          text: 'EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:MISSING_POSTAL_ADDRESS_TEXT',
          variant: 'error',
        });
      }
    } catch (error) {
      showSnackbar(t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:GET_POSTAL_ADDRESS_ERROR'), 'error');
    } finally {
      setIsloadingPostalAddress(false);
    }
  };

  /**
   * Request physical card
   */
  const handleConfirmRequestPhysicalCard = async () => {
    try {
      setIsLoadingRequestPhysicalCard(true);
      await apiClient.post('/client/requestPhysicalCardBusiness', {
        accountNumber: product.account_number,
      });
      setRequestPhysicalCardModalInfo({
        variant: 'success',
        title: t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:SUCCESS_TITLE'),
        text: 'EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:SUCCESS_TEXT',
        handleSubmit: handleMutate,
      });
    } catch (error) {
      setRequestPhysicalCardModalInfo(null);
      showSnackbar(t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:ERROR'), 'error');
    } finally {
      setIsLoadingRequestPhysicalCard(false);
    }
  };

  const options = [
    {
      text: t('EMPLOYEES:DETAIL:EDIT_CATEGORIES'),
      canViewOption: canViewOptionToEditCategories,
      onClick: () => setEditing(true),
    },
    {
      text: t('EMPLOYEES:DETAIL:BALANCE_RECOVERY'),
      canViewOption: Boolean(product.allow_recovery),
      disabled: !Number(product.balance_available),
      onClick: () => onPressRetrieveBalance(product),
    },
    {
      text: t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:TITLE_MENU'),
      canViewOption: canViewRequestPhysicalCardButton,
      disabled: Boolean(hasRequestedPhysicalCard),
      ...(Boolean(hasRequestedPhysicalCard) && {
        tooltipText: t('EMPLOYEES:DETAIL:REQUEST_PHYSICAL_CARD:PHYSICAL_CARD_ALREADY_REQUESTED'),
      }),
      onClick: handleRequestPhysicalCard,
    },
  ];

  return (
    <Box sx={styles.container}>
      {isMutating || isloadingPostalAddress ? (
        <Skeleton sx={styles.productSkeleton} variant='rectangular' />
      ) : (
        <>
          <CardHeader
            title={
              <>
                {product.account_number}
                <Chip label={product.status} color='primary' size='small' sx={{ marginLeft: 1 }} />
              </>
            }
            action={<ThreeDotsMenu options={options} />}
            sx={styles.padding0}
          />
          <Box sx={styles.padding0} component={isEditing ? 'form' : 'div'} ref={formRef}>
            <Stack spacing={3}>
              <List>{rows.map(renderRow)}</List>
              {product.cards.length > 0 ? (
                <Stack maxWidth='328px' spacing={2}>
                  {product.cards.map((card, index) => (
                    <PrepaidCard key={`card-${index}`} card={card} product={product.product} />
                  ))}
                </Stack>
              ) : null}
            </Stack>
          </Box>
          <AlertAtom
            modalVisible={editErrorSameProduct}
            handleClose={() => setEditErrorSameProduct(false)}
            title={t('EMPLOYEES:DETAIL:EDIT_CATEGORIES_FAILED_SAME_PRODUCT_TITLE')}
            description={t('EMPLOYEES:DETAIL:EDIT_CATEGORIES_FAILED_SAME_PRODUCT_DESCRIPTION')}
            closeButtonText={t('COMMON:ACCEPT')}
          />
          <AlertAtom
            variant={requestPhysicalCardModalInfo?.variant}
            modalVisible={Boolean(requestPhysicalCardModalInfo)}
            handleClose={() => setRequestPhysicalCardModalInfo(null)}
            {...(requestPhysicalCardModalInfo?.variant === 'warning' && {
              handleSubmit: handleConfirmRequestPhysicalCard,
            })}
            closeButtonText={
              requestPhysicalCardModalInfo?.variant === 'warning'
                ? 'COMMON:CANCEL'
                : 'COMMON:ACCEPT'
            }
            title={requestPhysicalCardModalInfo?.title}
            submitLoading={isLoadingRequestPhysicalCard}
            disabled={isLoadingRequestPhysicalCard}
            content={
              <Trans
                i18nKey={requestPhysicalCardModalInfo?.text}
                values={requestPhysicalCardModalInfo?.values}
                components={{ bold: <strong /> }}
              />
            }
          />
        </>
      )}
    </Box>
  );
};

export default ProductCard;
