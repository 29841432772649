import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Alert, AutocompleteComplex, Button, Input } from '@atoms';
import { useAddClient, useCustomerGroups } from '@hooks';
import { BoxContainer, FullWidthContent, MainMenuLayout } from '@layouts';
import { FIELDS, validationSchema } from './constants';
import styles from './styles';
/**
 * Renders a modal with the inputs fields and search clients
 * @param {object} props
 * @param  {boolean} props.isOpen is modal is or not visible
 * @param  {() => void | () => Promise<void>} props.onClose function to execuete after modal close
 * @param  {boolean} props.isLoading flag indicating whether the request is loading
 * @param  {object} props.params the current URL params
 * @param  {() => void | () => Promise<void>} props.onFiltersFormSubmit function to set clients data
 */
const AddClient = ({ isLoading }) => {
  const { t } = useTranslation();
  const { addClient, isError, setIsError, alertMessage, isSuccess, setIsSuccess } = useAddClient();
  const responsive = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const {
    data,
    isLoading: isLoadingCustomerGroups,
    isError: groupsError,
    setIsError: setGroupsError,
  } = useCustomerGroups();

  const { handleSubmit, handleChange, isValid, errors, values, onBlur, resetForm } = useFormik({
    initialValues: {},
    validationSchema,
    enableReinitialize: true,
    validateOnMount: false,
    onSubmit: (values, { resetForm }) => {
      addClient(values);
      resetForm({
        values: { values: { cuit: '', companyName: '', companyGroup: '', segment: '' } },
      });
    },
  });

  /**
   * Resets the form values and close modal
   */
  const handleCancel = () => {
    resetForm({ values: { cuit: '', companyName: '', companyGroup: null, segment: '' } });
  };

  const disableButton = !(values.cuit && values.companyName && values.segment) || !isValid;
  return (
    <MainMenuLayout>
      <FullWidthContent>
        <BoxContainer>
          <Typography variant='h4'>{t('CLIENTS:ADD_CLIENT')}</Typography>
          <Box style={styles.inputsContainer(responsive)}>
            {FIELDS.map(({ name, label, type }) => {
              const error = Boolean(name in values && errors[name]);
              const helperText = error ? errors[name] : '';

              return type === 'input' ? (
                <Input
                  key={name}
                  label={label}
                  variant='outlined'
                  size='small'
                  name={name}
                  value={values[name]}
                  onChange={handleChange(name)}
                  inputProps={{ onPaste: handleChange(name) }}
                  onBlur={onBlur}
                  helperText={helperText}
                  error={error}
                  fullWidth
                />
              ) : (
                <AutocompleteComplex
                  key={name}
                  label={label}
                  value={values[name]}
                  name={name}
                  onChange={handleChange(name)}
                  onBlur={onBlur}
                  InputProps={{ onPaste: handleChange(name) }}
                  error={error}
                  helperText={helperText}
                  items={data}
                  parseOption={({ id, name }) => ({ id: id, title: name })}
                  freeSolo={true}
                  disabled={isLoadingCustomerGroups || groupsError}
                  sx={styles.inputAutocomplete(responsive)}
                  size='medium'
                />
              );
            })}
          </Box>
          <Box sx={styles.buttonsBox}>
            <Button
              text={t('COMMON:CLEAR_FORM')}
              onClick={handleCancel}
              customStyle={styles.clearButton}
            />
            <Button
              variant='contained'
              color='primary'
              type='submit'
              isLoading={isLoading}
              disabled={disableButton}
              onClick={handleSubmit}
              text={t('COMMON:CONFIRM')}
            />
          </Box>
          <Alert
            modalVisible={isError || groupsError}
            handleClose={() => {
              setIsError(false);
              setGroupsError(false);
            }}
            variant='error'
            title={alertMessage.title}
            description={alertMessage.description}
            details={alertMessage.details}
          />
          <Alert
            modalVisible={isSuccess}
            handleClose={() => setIsSuccess(false)}
            variant='success'
            title={'CLIENTS:CREATE_CLIENT:SUCCESS_TITLE'}
            description={alertMessage.description}
            details={alertMessage.details}
          />
        </BoxContainer>
      </FullWidthContent>
    </MainMenuLayout>
  );
};

export default AddClient;

AddClient.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  params: PropTypes.object,
  retry: PropTypes.func,
  onFiltersFormSubmit: PropTypes.func,
};
