import { apiClient } from 'config/api';
import getCustomerDataByCompanyId from '../../../CompanyRepository/services/getCustomerDataByCompanyId';

export interface CompanyPostalAddress {
  customerId: number;
  street: string;
  number: number;
  floor?: number;
  apartment?: string;
  postalCode: string;
  cityName: string;
  stateName: string;
}

/** Fetch customer data by company id then fetch postal address by customer id  */
const getPostalAddressByCompanyId = async (companyId: string): Promise<CompanyPostalAddress> => {
  const customerData = await getCustomerDataByCompanyId(companyId);

  const { data: companyPostalAddress } = await apiClient.get(
    `/partner/address?customerId=${customerData.id}`
  );

  return companyPostalAddress;
};

export default getPostalAddressByCompanyId;
