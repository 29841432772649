const styles = {
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.5rem',
  },
  inputsContainer: (responsive) => ({
    display: 'flex',
    gap: '0.5rem',
    flexDirection: responsive ? 'column' : 'row',
  }),
  inputAutocomplete: (responsive) => ({
    display: 'flex',
    width: responsive ? '100%' : '220px',
  }),
  clearButton: {
    box: {
      alignSelf: 'center',
    },
    button: {
      color: 'primary.main',
      fontSize: '0.75rem',
      padding: '1rem 0.5rem',
    },
  },
};
export default styles;
